/**
 * @name: 平台协议接口
 * @author: itmobai
 * @date: 2023-07-19 14:29
 * @description：平台协议接口
 * @update: 2023-07-19 14:29
 */
import { get, postJ } from "@/request";
import { IPlatform, IPlatformParam } from "./types";
import { IPageRes } from "../page";

/**
 * 分页查询
 * @param query 查询条件
 * @returns
 */
export const mallAgreementQueryApi = (query: IPlatformParam) => get<IPageRes<IPlatform[]>>("/mall/agreement/query", query)
/**
 * 创建协议
 * @param data
 * @returns
 */
export const mallAgreementCreateApi = (data: IPlatform) => postJ("/mall/agreement/create", data)
/**
 * 修改协议
 * @param data
 * @returns
 */
export const mallAgreementModifyApi = (data: IPlatform) => postJ("/mall/agreement/modify", data)
/**
 * 协议详情
 * @param id
 * @returns
 */
export const mallAgreementDetailApi = (id: string) => get<IPlatform>("/mall/agreement/detail/" + id)
