
/**
 * @name: 隐私服务协议
 * @author: itmobai
 * @date: 2023-07-19 15:08
 * @description：隐私服务协议
 * @update: 2023-07-19 15:08
 */
import { Vue, Component } from "vue-property-decorator"
import { IPlatform, IPlatformParam } from "@/apis/platform/types"
import { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy } from "@/utils/common";
import { mallAgreementQueryApi, mallAgreementCreateApi, mallAgreementDetailApi, mallAgreementModifyApi } from "@/apis/platform"

@Component({})
export default class PlatformPage extends Vue {
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格数据
  tableData: IPlatform[] = []
  // 表格数据
  tableTotal: number = 0
  // 查询参数
  queryForm: IPlatformParam = {
    page: 1,
    limit: 10,
    types: 2
  }
  // 表单数据
  modelForm: Partial<IPlatform> = {}
  // 配置信息
  crudOption: ICrudOption = {
    labelWidth: '120px',
    dialogWidth: '800px',
    addTitle: '新增协议',
    menu: false,
    column: [
      {
        label: "ID",
        prop: "id",
        align: "left",
        width: 180,
        editHide: true,
        addHide: true,
        viewHide: true,
        slot: true
      },
      {
        label: "协议",
        prop: "antiStop",
        hide: true,
        addHide: true,
        editHide: true,
        viewHide: true,
        search: true,
        placeholder: "输入标题/ID模糊查询"
      },
      {
        label: "协议标题",
        prop: "agreementTitle",
        align: "center",
        overHidden: true,
        span: 24,
        maxlength: 15,
        rules: [
          {required: true, message: '请输入协议标题', trigger: 'blur'}
        ]
      },
      {
        label: "版本日期",
        prop: "versionTime",
        align: "center",
        width: "150",
        addHide: true,
        editHide: true,
        viewHide: true,
        slot: true
      },
      {
        label: "经办人",
        prop: "operatorName",
        align: "center",
        editHide: true,
        addHide: true,
        search: true,
        viewHide: true,
      },
      {
        label: "创建时间",
        prop: "addTime",
        align: "center",
        width: 180,
        editHide: true,
        addHide: true,
        viewHide: true,
        search: true,
        type: "daterange"
      },
      {
        label: "协议内容",
        prop: "agreementContent",
        hide: true,
        editSlot: true,
        addSlot: true,
        viewSlot: true,
        overHidden: true,
        span: 24,
        rules: [
          {required: true, message: '请输入协议内容', trigger: 'blur'}
        ]
      },
    ]
  }

  getList () {
    this.tableLoading = true
    const form: IPlatformParam = deepCopy(this.queryForm)
    if (form.addTime && form.addTime.length) {
      form.addStartTime = form.addTime[0]
      form.addEndTime = form.addTime[1]
    } else {
      form.addStartTime = ""
      form.addEndTime = ""
    }
    delete form.addTime
    mallAgreementQueryApi(form).then(e => {
      this.tableData = e.list;
      this.tableTotal = e.total;
      this.tableLoading = false
    })
  }

  rowSave (form: IPlatform, done: Function, loading: Function) {
    form.types = 2
    mallAgreementCreateApi(form).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  openDetail (row: IPlatform, index: number) {
    mallAgreementDetailApi(row.id as string).then(e => {
      if (e) {
        // @ts-ignore
        this.$refs.crudRef.rowView(e, index)
      }
    })
  }

  created () {
    this.getList()
  }
}
